<template>
    <div class="c-amend-search" v-if="currentlyAmending">
        <div v-if="currentlyAmending == 'accommodation'" class="c-amend-search__wrapper">
            <SearchType />
        </div>

        <div v-if="currentlyAmending == 'park'" class="c-amend-search__wrapper">
            <SearchList />
        </div>

        <div v-if="currentlyAmending == 'duration'" class="c-amend-search__wrapper">
            <SearchDuration />
            <GlobalButton @click="closeAmendView"  title="Update Selection" class="my-2" />
            <span class="text-center mt-2"><p>Call <a href="tel:01524701508" class="font-bold">01524 701508</a> for longer bookings</p></span>
        </div>

        <div v-if="currentlyAmending == 'guests'" class="c-amend-search__wrapper">
            <SearchGuests />
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        currentlyAmending: {
            type: String,
            required: true,
        },
    });
    const emit = defineEmits(['update:currentlyAmending']);

    const closeAmendView = () => {
        emit('update:currentlyAmending', null);
    };
</script>